import { classNames } from "components/library/utils/classNames";
import { useBreakpoints } from "lib/use-breakpoints";
import React from "react";

export const TagsBackground: React.FC = ({ children }) => {
  const { isMediumWidth, isLargeWidth } = useBreakpoints();

  return (
    <div className="relative">
      <div className="absolute top-0 bottom-0 left-0 right-0 -z-10 overflow-hidden bg-gray-100">
        <div
          className={classNames("absolute right-0 bottom-0 h-full w-full min-w-[2500] bg-center  bg-no-repeat", isMediumWidth ? "top-4" : "top-80 bg-cover")}
          style={{
            backgroundImage: isMediumWidth ? `url("/assets/backgrounds/tag-silhouette.png")` : `url("/assets//backgrounds/tag-silhouette-mobile.png")`,
          }}
        />
      </div>
      {children}
    </div>
  );
};
