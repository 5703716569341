import IconButton from "@components/library/IconButton";
import { hlToast, ToastVariant } from "@components/library/Toast";
import { ExclamationIcon, XIcon } from "@heroicons/react/solid";
import Button from "@library/Button";
import { Form, Formik } from "formik";
import { useSearchString } from "lib/use-search-string";
import { safeQueryParamRedirect } from "lib/xss-prevention";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import * as Yup from "yup";
import { useAuth } from "../../lib/use-auth";
import { FormikTextInput } from "../molecules/FormikInput";

export default function LoginForm() {
  const router = useRouter();
  const searchString = useSearchString();
  const auth = useAuth();

  const nextUrl = safeQueryParamRedirect(router.query.next);
  const redirectTo = searchString ? `${nextUrl}?${searchString}` : nextUrl;

  return (
    <Formik
      initialValues={{ email_address: "", password: "" }}
      validationSchema={Yup.object({
        email_address: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().min(8, "Passwords are longer than 8 characters").required("Required"),
      })}
      onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
        setSubmitting(true);
        const response = await auth.login(values.email_address, values.password);
        setStatus();
        if (response.status === 401) {
          setStatus({ error: "Incorrect email or password" });
          return;
        } else if (response.status >= 500) {
          hlToast({
            title: "Something went wrong",
            description: "Please try again later",
            variant: ToastVariant.Error,
          });
        }
        if (response.status === 200) {
          router.push(redirectTo);
        }
      }}
    >
      {({ errors, isSubmitting, status, setStatus }) => (
        <Form className="flex grow flex-col justify-between">
          <div className="flex flex-col gap-20">
            <FormikTextInput type="email" name="email_address" label="Email" tabIndex={1} />
            <FormikTextInput
              name="password"
              type="password"
              label="Password"
              tabIndex={2}
              corner={
                <Link href={searchString ? `/forgot-password?${searchString}` : `/forgot-password`}>
                  <a className="font-medium transition-default text-blue-600 hover:text-blue-700 focus:underline focus:outline-none">Forgot password?</a>
                </Link>
              }
            />
          </div>
          <div className="">
            {status?.error && (
              <div className=" mb-16 flex animate-fade-in-down items-center justify-between rounded border border-red-300 bg-red-100 px-16 py-12">
                {status?.error && <div className="text-14-16 text-red-800">{status.error}</div>}
                <IconButton
                  onClick={() => {
                    setStatus();
                  }}
                  Icon={XIcon}
                  className="text-red-500"
                  size={32}
                />
                {/* {status.error} */}
              </div>
            )}
            <Button type="submit" size={40} styling="solid" className="w-full" loading={isSubmitting}>
              {isSubmitting ? "Logging in..." : "Log in"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
