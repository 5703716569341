import Anchor from "@components/atoms/Anchor";
import { TagsBackground } from "@components/atoms/TagsBackground";
import { LLAMA_DOCS } from "lib/constants";
import { classNames } from "lib/utils";
import React from "react";

interface SignupLayoutProps {
  className?: string;
}

export const HelpSnippet = () => (
  <div className="flex gap-8 text-12-16 text-gray-800">
    <div>Need help?</div>
    <div className="flex gap-[3px]">
      <Anchor href={LLAMA_DOCS} target="_blank" className="text-blue-600">
        Visit docs
      </Anchor>{" "}
      or{" "}
      <a href="mailto:help@humanloop.com" className="text-blue-600">
        contact us
      </a>
    </div>
  </div>
);

export const SignupLayout = ({ children, className }: React.PropsWithChildren<SignupLayoutProps>) => {
  return (
    <TagsBackground>
      <div className="flex min-h-screen w-screen items-center justify-center">
        <main
          className={classNames("relative flex w-[480px] flex-col items-center rounded-lg bg-white py-64 lg:w-[768px]", className)}
          style={{
            boxShadow:
              "0px 66px 26px rgba(0, 0, 0, 0.02), 0px 37px 22px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.09), 0px 4px 9px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="flex max-w-[320px] grow flex-col items-center gap-48">{children}</div>
        </main>
      </div>
    </TagsBackground>
  );
};

export default SignupLayout;
