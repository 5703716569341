import DeviceSupport from "@components/atoms/DeviceSupport";
import { LissajousLogo } from "@components/library/LissajousLogo";
import CardIcon from "@components/llama/Signup/CardIcon";
import SignupLayout from "@components/llama/Signup/SignupLayout";
import CustomHead from "@components/templates/CustomHead";
import LoginForm from "components/organisms/LoginForm";
import { useAuth } from "lib/use-auth";
import { safeQueryParamRedirect } from "lib/xss-prevention";
import { useRouter } from "next/router";
import React, { ReactElement, useEffect } from "react";

const LoginPage: NextTypes.NextPageWithLayout = () => {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!auth.loading && auth.user) {
      router.replace(safeQueryParamRedirect(router.query.next));
    }
  });

  return (
    <div className="flex grow flex-col justify-between">
      <div className="flex w-[300px] flex-col items-center gap-24">
        <CardIcon Icon={LissajousLogo} />
        <div className="text-18-24 font-bold">Log in to Humanloop</div>
        {/* <div className="text-center text-14-20 text-gray-700">
        Check <span className="font-bold text-gray-800">{signedUpEmail}</span> to verify your account and get started.
      </div> */}
      </div>

      <div className="mt-[72px] flex grow">
        <LoginForm />
      </div>
    </div>
  );
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <SignupLayout className="min-h-[640px] justify-center">
      <DeviceSupport />
      <CustomHead pageName="Log in" />
      {page}
    </SignupLayout>
  );
};

export default LoginPage;
