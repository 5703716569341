interface Props {
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
}

const CardIcon = ({ Icon }: Props) => {
  return (
    <div
      className="flex h-56 w-56 items-center justify-center rounded-lg border border-gray-400"
      style={{ boxShadow: "0px 1px 0px rgba(187, 187, 187, 0.25), 0px 2px 4px rgba(221, 221, 221, 0.5)" }}
    >
      <Icon className="h-24 w-24" />
    </div>
  );
};

export default CardIcon;
