const relativeURLs = ["/login", "/signup", "/forgot-password", "/projects", "/profile", "/data", "/models", "/llama", "/playground"];

/*
Checks if URL is a known relative url.
Allows safe use of router.replace/push, preventing reflected XSS attacks.
(e.g. where these attributes are set to `javascript:alert(1)`)
*/
export const isKnownRelativeURL = (str: string): boolean => {
  return relativeURLs.some((url) => str.startsWith(url));
};

export const safeQueryParamRedirect = (queryParam: string | string[]): string | null => {
  // Query params can be arrays if the same key is used multiple times.
  const fallback = "/";
  if (!queryParam) return fallback;
  if (typeof queryParam === "string") {
    return isKnownRelativeURL(queryParam) ? queryParam : fallback;
  } else if (queryParam && Array.isArray(queryParam)) {
    return isKnownRelativeURL(queryParam[0]) ? queryParam[0] : fallback;
  }
};
