import { useRouter } from "next/router";
import { useState, useEffect } from "react";

export function useSearchString() {
  const router = useRouter();

  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    setSearchString(router.asPath.split("?")[1]);
  }, [router.asPath]);

  return searchString;
}
